import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colorsV2 } from '../../style/colors-v2';
import { SpokeLoading } from '../SpokeLoading/SpokeLoading';

const StyledButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 10px 16px;
  border: 1px solid;
  border-color: ${colorsV2.primary100};
  border-radius: 8px;
  background-color: transparent;
  color: ${colorsV2.primary100};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    border-color: ${colorsV2.primary80};
    color: ${colorsV2.primary80};
  }

  &:active {
    border-color: ${colorsV2.primary60};
    color: ${colorsV2.primary60};
  }

  &:disabled,
  &.disabled {
    border-color: ${colorsV2.gray80};
    color: ${colorsV2.gray80};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonLoadMore = props => {
  const { loading = false, text, onClick } = props;

  if (loading) {
    return (
      <Container>
        <SpokeLoading width={20} height={20} color={colorsV2.primary100} />
      </Container>
    );
  }

  return (
    <Container onClick={onClick}>
      <StyledButton>{text}</StyledButton>
    </Container>
  );
};

ButtonLoadMore.propTypes = {
  loading: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func
};
