import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import cl from 'classnames';
import styled from 'styled-components';

import { colorsV2 } from 'style/colors-v2';
import { deviceBreakpoints } from 'utils/hooks/useDeviceQuery.hook';

import IconClose from './icons/Close';

/* eslint-disable react/prop-types */
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  pointer-events: auto;
  overflow: hidden;
  visibility: hidden;
  z-index: 100000; // This is for override FB Chat index

  display: grid;
  place-items: center;

  &.active {
    visibility: visible;
  }
`;

const Wrapper = styled.div`
  background: ${colorsV2.white100};
  position: absolute;
  border-radius: 8px;
  width: 100%;
  min-width: 320px;
  padding: 12px;
  .iframe-container {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
  .embed-container {
    height: 0;
    width: 100%;
    margin-bottom: 18px;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;
  }

  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    height: 100% !important;
    left: 0;
    position: absolute;
    top: 0;
    width: 100% !important;
  }
  .modal-header {
    margin-bottom: 24px;
    display: flex;
    .title {
      flex: 1;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    .icon {
      color: ${colorsV2.gray100};
      font-size: 24px;
      line-height: 0;
      cursor: pointer;
      &:hover {
        color: ${colorsV2.primary40};
      }
    }
  }
  .modal-content {
    display: grid;
    place-items: center;
    video {
      max-height: calc(100vh - 190px);
      width: 100%;
    }
  }
  @media ${deviceBreakpoints.md} {
    padding: 24px;
    width: 680px;
  }
  @media ${deviceBreakpoints.lg} {
    width: 1120px;
  }
`;

class Modal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    children: PropTypes.node
  };

  constructor(props) {
    super(props);
    this.modelRef = React.createRef();
  }

  componentDidUpdate() {
    document.addEventListener('keydown', evt => {
      if (evt.keyCode === 27) {
        this.onClose();
      }
    });
  }

  onClose = () => {
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  };

  render() {
    const { title, isOpen, children } = this.props;

    if (!isOpen) {
      return '';
    }

    return ReactDOM.createPortal(
      <Backdrop
        onClick={this.onClose}
        data-modal
        className={cl({ active: isOpen })}
      >
        <Wrapper onClick={e => e.stopPropagation()} ref={this.modelRef}>
          <div className="modal-header">
            <div className="title">{title}</div>
            <div onClick={this.onClose} className="icon">
              <IconClose />
            </div>
          </div>
          <div className="modal-content">{children}</div>
        </Wrapper>
      </Backdrop>,
      document.body
    );
  }
}

export default Modal;
