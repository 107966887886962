// this component will return a element when `to` prop is passing

import React from 'react';
import PropTypes from 'prop-types';

import { Link } from '@reach/router';
import styled, { css } from 'styled-components';

import Icon from '../Icon';
import { colorsV2 } from '../../style/colors-v2';

const outline = css`
  border: 1px solid #d22222;
  background-color: transparent !important;
  color: #d22222;
`;

const light = css`
  border: 1px solid #e9e9e9;
  background-color: white;
  color: #d22222;
`;

const secondary = css`
  border: 1px solid #999999;
  background-color: white;
  color: #999999;

  .iconLeft,
  .iconRight {
    color: #999999;
  }

  &:hover {
    border-color: #d22222;
    color: #d22222;

    .iconLeft,
    .iconRight {
      color: #d22222;
    }
  }
`;

const secondaryNew = css`
  border: none;
  background-color: #f4f4f6;
  border-radius: 8px;

  &:hover {
    background-color: #e9e9ec;
  }
  &:disabled {
    background-color: #bcbec7;
  }
`;

const sharedCss = css`
  color: white;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  width: ${props => props.width || 'auto'};
  min-width: ${props => props.minWidth || 'auto'};
  background-color: ${colorsV2.primary100};
  border: 1px solid ${colorsV2.primary100};
  text-decoration: none;
  display: inline-block;
  padding: 0 1.5em;
  height: ${props => (props.size === 'sm' ? '36px' : '44px')};
  /* minus 2px for border */
  line-height: ${props => (props.size === 'sm' ? '34px' : '42px')};
  transition: all 0.3s;

  &:disabled,
  &.disabled {
    opacity: 0.75;
    pointer-events: none;
    background-color: #9b9b9b;
    border-color: #9b9b9b;
  }

  .iconLeft {
    color: ${colorsV2.primary100};
    margin-right: 6px;
  }
  .iconRight {
    color: #d22222;
    margin-left: 6px;
  }

  ${props => (props.outline ? outline : '')}
  ${props => (props.light ? light : '')}
  ${props => (props.secondary ? secondary : '')}
  ${props => (props.secondaryNew ? secondaryNew : '')}
`;

const ExternalLink = styled.a`
  ${sharedCss}
`;

const InternalLink = styled(Link)`
  ${sharedCss}
`;

const StyledButton = styled.button`
  ${sharedCss}
`;

const Button = ({
  to,
  disabled = false,
  leftIconType,
  rightIconType,
  children,
  ...props
}) => {
  if (to) {
    if (to.startsWith('http')) {
      return (
        <ExternalLink
          {...props}
          href={to}
          className={disabled ? 'disabled' : ''}
        >
          {leftIconType && <Icon className="iconLeft" type={leftIconType} />}
          {children}
          {rightIconType && <Icon className="iconRight" type={rightIconType} />}
        </ExternalLink>
      );
    }
    return (
      <InternalLink {...props} to={to} className={disabled ? 'disabled' : ''}>
        {leftIconType && <Icon className="iconLeft" type={leftIconType} />}
        {children}
        {rightIconType && <Icon className="iconRight" type={rightIconType} />}
      </InternalLink>
    );
  }

  return (
    <StyledButton {...props} disabled={disabled}>
      {leftIconType && <Icon className="iconLeft" type={leftIconType} />}
      {children}
      {rightIconType && <Icon className="iconRight" type={rightIconType} />}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  leftIconType: PropTypes.string,
  rightIconType: PropTypes.string,
  to: PropTypes.shape({
    startsWith: PropTypes.func
  })
};

export default Button;
