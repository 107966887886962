import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loadable from 'react-loadable';

const Wrapper = styled.i`
  display: inline-block;
  font-size: 1rem;
  text-align: center;
  line-height: 0;
  vertical-align: -0.125em;
`;

const Icon = ({ type, className, ...rest }) => {
  const SVGIcon = Loadable({
    loader: () => import(`./SVGIcons/${type}`),
    loading() {
      return null;
    }
  });
  return (
    <Wrapper className={className}>
      {SVGIcon ? (
        <SVGIcon width="1em" height="1em" {...rest} />
      ) : (
        `Missing icon ${type}`
      )}
    </Wrapper>
  );
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string
};

Icon.defaultProps = {
  className: ''
};

export default Icon;
