/* eslint-disable react/prop-types */
import * as React from 'react';
import { take } from 'lodash';
import cl from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import { colorsV2 } from 'style/colors-v2';

import { Typography } from '../../components/DesignSystemV2/Typography';
import Modal from '../CourseLanding/Modal';
import { fromScreen } from '../../utils/media-query/responsive.util';
import IconButtonPlay from './icons/IconButtonPlay';
import { SharedTextStyle } from './SharedTextStyle';
import { ButtonLoadMore } from '../../components/ButtonLoadMore/ButtonLoadMore';

const Main = styled.section`
  ${SharedTextStyle}
  display: grid;
  grid-auto-rows: max-content;
  gap: 24px;
  ${fromScreen(776)} {
    gap: 40px;
  }

  .slider {
    align-items: flex-start;
  }
  .video-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    ${fromScreen(776)} {
      grid-template-columns: 1fr 1fr;
    }
    ${fromScreen(1144)} {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .mobile {
    display: grid;

    ${fromScreen(776)} {
      display: none;
    }
  }
  .from776 {
    display: none;

    ${fromScreen(776)} {
      display: grid;
    }
  }
`;

const CardVideoMain = styled.div`
  text-align: left;
  display: grid;
  grid-auto-rows: max-content;
  gap: 12px;
  width: 100%;
  .video-box {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    .video-wrapper {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: grid;
      place-items: center;
      transition: 0.2s all;
      cursor: pointer;
      &:active {
        .play-button {
          transform: scale(0.9);
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .play-button {
        transition: 0.2s all;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(3.72727px);
        width: 64px;
        min-width: 64px;
        height: 64px;
        min-height: 64px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        font-size: 30px;
        color: ${colorsV2.white100};
      }
      > * {
        pointer-events: none;
      }

      &:hover {
        .play-button {
          color: ${colorsV2.blue60};
        }
      }
    }
  }
`;

const VideoPlayerMain = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  iframe {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
  }
`;

export const VideoPlayer = ({ videoUrl, autoPlay }) => {
  const isYoutubeVideo = videoUrl.includes('youtu');

  return (
    <VideoPlayerMain>
      {isYoutubeVideo ? (
        <div className="iframe-container">
          <iframe
            id="video-auto-play-modal"
            src={`${videoUrl}?autoplay=${autoPlay}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      ) : (
        <video
          className="videoPlayer"
          controls
          preload="meta"
          autoPlay={autoPlay}
          id="video-auto-play-modal"
          src={videoUrl}
          type="video/mp4"
        >
          <track kind="captions" />
        </video>
      )}
    </VideoPlayerMain>
  );
};

const CardVideo = ({ data, onPlayVideo }) => {
  const { title, thumbnailUrl } = data;
  return (
    <CardVideoMain className={cl('keen-slider__slide', 'card-video')}>
      <div
        onClick={e => {
          e.preventDefault();
          onPlayVideo();
        }}
        className="video-box"
      >
        <div className="video-wrapper">
          <div className="overlay">
            <div className="play-button">
              <IconButtonPlay />
            </div>
          </div>
          <img loading="lazy" alt={title} src={thumbnailUrl} />
        </div>
      </div>
      <div className="video-title">
        <Typography variant="semi-bold/20">{title}</Typography>
      </div>
    </CardVideoMain>
  );
};

const YoutubeVideoCourses = () => {
  const data = useStaticQuery(graphql`
    {
      gcms {
        jsonContanier(where: { name: "Linearthinking_Page" }) {
          content
        }
      }
    }
  `);

  const { videos } = data.gcms.jsonContanier.content;
  const [showModal, setShowModal] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState('');
  const [mobileLoadedNumber, setMobileLoadedNumber] = React.useState(3);

  const title = 'Video bài giảng Linearthinking';

  const renderVideoList = React.useCallback(
    ({ videos, loaded, onLoadMore }) => {
      return (
        <>
          {(loaded > 0 ? take(videos, loaded) : videos).map(video => {
            return (
              <CardVideo
                key={video.id}
                onPlayVideo={() => {
                  setVideoUrl(video.videoUrl);
                  setShowModal(true);
                }}
                data={video}
              />
            );
          })}
          {Boolean(loaded) && loaded < videos.length && (
            <ButtonLoadMore text="Xem thêm video" onClick={onLoadMore} />
          )}
        </>
      );
    },
    []
  );

  return (
    <Main className="youtube-video-courses">
      <h2 className="heading-center-desktop section-title">{title}</h2>
      <div className="from776 video-list">{renderVideoList({ videos })}</div>
      <div className="mobile video-list">
        {renderVideoList({
          videos,
          loaded: mobileLoadedNumber,
          onLoadMore: () => setMobileLoadedNumber(mobileLoadedNumber + 3)
        })}
      </div>

      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <VideoPlayer videoUrl={videoUrl} autoPlay />
      </Modal>
    </Main>
  );
};

export default YoutubeVideoCourses;
