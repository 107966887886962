import { css } from 'styled-components';
import { colorsV2 } from 'style/colors-v2';
import { fromScreen } from 'utils/media-query/responsive.util';

export const SharedTextStyle = css`
  .section-title {
    margin: 0;
    text-align: left;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${colorsV2.black100};
    ${fromScreen(458)} {
      font-size: 32px;
      line-height: 40px;
    }
    ${fromScreen(1144)} {
      font-size: 40px;
      line-height: 48px;
    }
  }

  .section-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${colorsV2.black100};
    ${fromScreen(1144)} {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .heading-center-desktop {
    ${fromScreen(776)} {
      text-align: center;
    }
  }
`;
