import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colorsV2 } from '../../style/colors-v2';

const Main = styled.div``;

export const SpokeLoading = props => {
  const { color = colorsV2.white100, width = 80, height = 80 } = props;
  const style = React.useMemo(() => {
    return {
      fill: color,
      height: Number(height) || height,
      width: Number(width) || width
    };
  }, [color, height, width]);

  return (
    <Main style={style} className="dol-spoke-loading">
      <svg id="loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path opacity=".1" d="M14 0 H18 V8 H14 z" transform="rotate(0 16 16)">
          <animate
            attributeName="opacity"
            from="1"
            to=".1"
            dur="1s"
            repeatCount="indefinite"
            begin="0"
          />
        </path>
        <path opacity=".1" d="M14 0 H18 V8 H14 z" transform="rotate(45 16 16)">
          <animate
            attributeName="opacity"
            from="1"
            to=".1"
            dur="1s"
            repeatCount="indefinite"
            begin="0.125s"
          />
        </path>
        <path opacity=".1" d="M14 0 H18 V8 H14 z" transform="rotate(90 16 16)">
          <animate
            attributeName="opacity"
            from="1"
            to=".1"
            dur="1s"
            repeatCount="indefinite"
            begin="0.25s"
          />
        </path>
        <path opacity=".1" d="M14 0 H18 V8 H14 z" transform="rotate(135 16 16)">
          <animate
            attributeName="opacity"
            from="1"
            to=".1"
            dur="1s"
            repeatCount="indefinite"
            begin="0.375s"
          />
        </path>
        <path opacity=".1" d="M14 0 H18 V8 H14 z" transform="rotate(180 16 16)">
          <animate
            attributeName="opacity"
            from="1"
            to=".1"
            dur="1s"
            repeatCount="indefinite"
            begin="0.5s"
          />
        </path>
        <path opacity=".1" d="M14 0 H18 V8 H14 z" transform="rotate(225 16 16)">
          <animate
            attributeName="opacity"
            from="1"
            to=".1"
            dur="1s"
            repeatCount="indefinite"
            begin="0.675s"
          />
        </path>
        <path opacity=".1" d="M14 0 H18 V8 H14 z" transform="rotate(270 16 16)">
          <animate
            attributeName="opacity"
            from="1"
            to=".1"
            dur="1s"
            repeatCount="indefinite"
            begin="0.75s"
          />
        </path>
        <path opacity=".1" d="M14 0 H18 V8 H14 z" transform="rotate(315 16 16)">
          <animate
            attributeName="opacity"
            from="1"
            to=".1"
            dur="1s"
            repeatCount="indefinite"
            begin="0.875s"
          />
        </path>
      </svg>
    </Main>
  );
};

SpokeLoading.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};
